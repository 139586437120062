// @ts-nocheck
import React from "react";

import { graphql } from "gatsby";

import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { Calendly } from "@/components/Calendly";

import { useSiteMetadata } from "@/hooks";

import * as styles from "./contact.module.scss";

const ContactPage: React.FC<Props> = () => {
  return (
    <Layout>
      <Sidebar />
      <Page title="Contact Me">
        <form
          action="https://getform.io/f/5eV4gBbE"
          method="POST"
          className={styles.form}
        >
          <div>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" />
          </div>

          <div>
            <label htmlFor="text">Note</label>
            <textarea type="text" name="message" />
          </div>

          {/* <!-- add hidden Honeypot input to prevent spams --> */}
          <input type="hidden" name="_gotcha" style={{ display: "none !important"}} />

          <button type="submit">Send</button>
        </form>
      </Page>
    </Layout>
  );
};

export const Head: React.FC<Props> = () => {
  const { title } = useSiteMetadata();

  return (
    <Meta
      title={`Contact Me - ${title}`}
      description="Contact Me"
    />
  );
};

export default ContactPage;
